import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImageProps } from "gatsby-image"

import Layout from "../../components/layout"
import List from "../../components/list"
import ListItem from "../../components/list-item"
import ListItemLink from "../../components/list-item-link"

export const peopleQuery = graphql`
  query PeopleQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___name] }
      filter: { frontmatter: { templateKey: { eq: "person" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            templateKey
            image {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
interface PeopleProps {
  allMarkdownRemark: {
    edges: {
      node: {
        id: string
        fields: {
          slug: string
        }
        frontmatter: {
          name: string
          templateKey: string
          image: GatsbyImageProps
        }
      }
    }[]
  }
}
const People: React.FC = () => (
  <StaticQuery
    query={peopleQuery}
    render={(props: PeopleProps) => {
      const { allMarkdownRemark } = props
      const { edges } = allMarkdownRemark
      return (
        <Layout>
          <h1>People</h1>
          <List>
            {edges.map(edge => {
              const {
                node: {
                  id,
                  fields: { slug },
                  frontmatter: { name, image },
                },
              } = edge

              return (
                <ListItem key={`people-person-${id}`}>
                  <ListItemLink to={slug}>{name}</ListItemLink>
                </ListItem>
              )
            })}
          </List>
        </Layout>
      )
    }}
  />
)

export default People
